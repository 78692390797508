<template>
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center" style="height: 80vh">
      <div class="card" style="min-width: 800px;">
        <div class="card-body">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="align-self-center w-50">
              <img :src="require('@/assets/images/undraw/logistics.svg')" height="256px" class="mx-auto d-block">
            </div>
            <div class="align-self-center w-50">
              <div class="form-group">
                <h3 class="">
                  Account Select
                </h3>
                <p v-if="isServingTenant">
                  Please enter  <span class="text-warning font-weight-bold"> company name </span> or <span class="text-warning font-weight-bold"> account number </span> below,
                  and then select <span class="text-warning font-weight-bold"> the store. </span>
                </p>
                <p v-if="isIndividualTenant">
                  Please select <span class="text-warning font-weight-bold"> the store. </span>
                </p>
                <div>
                  <dx-util-select-box
                    v-if="isServingTenant"
                    :data-source="customerCompanies"
                    :min-search-length="2"
                    :max-item-count="10"
                    :show-clear-button="true"
                    display-expr="companyNameWithAccount"
                    :search-enabled="true"
                    :search-expr="['accountNo', 'name']"
                    search-mode="contains"
                    placeholder="Company Name / Account No"
                    class="my-2 mr-half h4"
                    style="height: 50px;"
                    @input="debouncedSearchCompany"
                    @focusIn="onFocusIn"
                    @contentReady="focusIt"
                    @valueChanged="selectCompany"
                  />
                </div>
              </div>
              <div>
                <dx-util-select-box
                    :key="rerenderKey"
                    :data-source="storeList"
                    :max-item-count="10"
                    :show-clear-button="true"
                    search-mode="contains"
                    search-expr="storeName"
                    display-expr="storeName"
                    value-expr="storeId"
                    placeholder="Select a store"
                    class="my-2 mr-half h4"
                    style="height: 50px;"
                    :defer-rendering="true"
                    :focus-state-enabled="true"
                    @selectionChanged="selectStore"
                  />
              </div>
              <div v-if="showNoStoreWarning">
                <p class="text-warning">
                  This account does not have any stores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import {
  onMounted, ref,
} from '@vue/composition-api'
import router from '@/router'
import { useDebounceFn } from '@vueuse/core'
import useCurrentUser from '@/libs/app/current-user'
import store from '@/store'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import settingsService from '@/http/requests/common/settings'
import { Notify } from '@robustshell/utils/index'
import companyService from '@/http/requests/company/companyService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'

export default {
  components: {
  },
  setup() {
    const {
      userAccountNo, userCompanyId, userCompanyName, isIndividualTenant, isServingTenant,
    } = useCurrentUser()
    const customerCompanies = ref([])
    const storeList = ref([])

    function searchCompany(e) {
      storeList.value = []
      const query = e.component.option('text')
      if (query === '' || query === undefined || query.length < 3) {
        return
      }
      const params = { name: query, type: 'customer' }
      companyService.fetchAllMatchingNameAndType(params).then(response => {
        const data = []
        response.data.forEach(el => {
          data.push({
            companyNameWithAccount: `${el.accountNo} : ${el.name}`, name: el.name, accountNo: el.accountNo, companyId: el.id,
          })
        })
        customerCompanies.value = data
      })
    }

    const debounceDelay = 500
    const debouncedSearchCompany = useDebounceFn(e => {
      searchCompany(e)
    }, debounceDelay)

    const selectedCompany = ref(null)
    const selectedStore = ref(null)

    async function getStorePrinterSetting(storeId) {
      tenantService.findByKeyAndReference(managedKeysEnum.CURRENTSELECTEDPRINTER.key, storeId).then(result => {
        const data = { ...result.data }
        if (data.id) {
          const printer = JSON.parse(data.value)
          const currentSelectedPrinter = {
            LabelId: printer.LabelId,
            LabelName: printer.LabelName,
            Address: printer.Address,
            PrinterName: printer.PrinterName,
            VendorName: printer.VendorName,
            printer: { ...printer.printer },
            manageLabelPrintering: { ...printer.manageLabelPrintering },
          }
          store.dispatch('fbaShipment/setPrinterSettings', currentSelectedPrinter)
          localStorage.setItem('printerSettings', JSON.stringify(currentSelectedPrinter))
        } else {
          Notify.warning('There is no default printer for this store yet.')
        }
      })
    }

    async function getStoreAutoPriceSetting(storeId) {
      const result = await settingsService.getByReference(referenceTypeEnum.STORE.value, storeId, SettingsTypeEnum.AUTO_PRICING.value)
      const resultObj = JSON.parse(result.data.value)
      if (resultObj && typeof resultObj === 'object') {
        const channels = resultObj?.channels
        const listPrice = { ...resultObj?.listPrice }
        const minimumPrice = { ...resultObj?.minimumPrice }
        const autoPricingSettings = {
          channels,
          listPrice,
          minimumPrice,
        }
        const isExisting = localStorage.getItem('autoPricingSettings')
        if (isExisting) {
          localStorage.removeItem('autoPricingSettings')
        }
        localStorage.setItem('autoPricingSettings', JSON.stringify(autoPricingSettings))
      }
    }

    async function openFBAShipments() {
      if (!selectedStore.value.isAuthorized) {
        Notify.error('This store has not been authorized the owner yet. Please ask the owner of the store to authorize PrepShipHub '
        + 'on Amazon.com by following authorization process at Dashboards > Dashboard page or Account > My Company > Stores Tab')
        return
      }
      const accountInfo = {
        accountNo: selectedCompany.value.accountNo,
        companyId: selectedCompany.value.companyId,
        companyName: selectedCompany.value.name,
        storeId: selectedStore.value.storeId,
        storeName: selectedStore.value.storeName,
      }
      accountInfo.accountNo = accountInfo.accountNo.toString()
      store.dispatch('fbaShipment/setBatchAccountInfo', accountInfo)
      router
        .push({
          name: 'fba-shipments-workflow',
          params: {
            accountNo: accountInfo.accountNo,
            workflow: 'batching',
          },
        })
        .catch(error => {
          // this.showError(error, 'Access')
          Notify.warning(error)
        })
    }

    const showNoStoreWarning = ref(false)

    async function getStoreListByAccountNo(accountNo) {
      storeList.value = []
      commonEntityService
        .fetcStoresByCompanyAccountNoAndChannel(accountNo, 'fba')
        .then(async result => {
          const data = []
          result.data.forEach(element => {
            data.push({
              storeId: element.id,
              storeName: element.name,
              isAuthorized: element.isAuthorized,
            })
          })
          storeList.value = data
          showNoStoreWarning.value = storeList.value.length === 0
          if (storeList.value.length === 1) {
            selectedStore.value = storeList.value[0]
            await getStorePrinterSetting(selectedStore.value.storeId)
            await getStoreAutoPriceSetting(selectedStore.value.storeId)
            openFBAShipments()
          }
        })
    }

    async function selectCompany(e) {
      selectedCompany.value = e.value
      await getStoreListByAccountNo(selectedCompany.value.accountNo)
    }

    function focusIt(e) {
      e.component.focus()
    }

    const rerenderKey = ref(0)
    function onFocusIn(e) {
      e.component.reset()
      storeList.value = []
      selectedStore.value = null
      rerenderKey.value += 1
    }

    async function selectStore(e) {
      selectedStore.value = e.selectedItem
      const storeId = selectedStore.value.storeId
      await getStorePrinterSetting(storeId)
      await getStoreAutoPriceSetting(storeId)
      await openFBAShipments()
    }

    async function passIndividualTenantAutomatically() {
      if (isIndividualTenant.value) {
        selectedCompany.value = {
          accountNo: userAccountNo?.value,
          companyId: userCompanyId?.value,
          name: userCompanyName?.value,
        }
        await getStoreListByAccountNo(userAccountNo.value)
      }
    }

    onMounted(() => {
      passIndividualTenantAutomatically()
    })

    return {
      customerCompanies,
      searchCompany,
      focusIt,
      selectCompany,
      debouncedSearchCompany,
      selectStore,
      onFocusIn,
      storeList,
      showNoStoreWarning,
      selectedStore,
      isIndividualTenant,
      isServingTenant,
      rerenderKey,
    }
  },
}
</script>
<style lang="scss">
.form-avatar {
  height: 256px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#btnStartProccessing,
#btnStartProccessing .dx-button-content {
  padding: 0;
  .dx-button-text {
    color: aliceblue;
  }
}
#btnStartProccessing .button-indicator {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  color: aliceblue;
  margin-right: 10px;
}
</style>
